import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Tooltip } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  BLType as BL,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";

export default function BLTooltip({
  management,
  freightType,
}: {
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const { BLType, mBL, hBL } = management;

  if (!freightType) return null;

  const BLLabelRecord: Partial<Record<BL, string>> = {
    FCL: {
      ALL: "MBL/HBL",
      DirectMBL: "MBL Direct",
    },
    LCL: {
      ALL: "MBL/HBL",
      HBL: "HBL",
    },
    AIR: {
      ALL: "MAWB/HAWB",
      DirectMBL: "MAWB Direct",
    },
  }[freightType];

  const BLInfoRecord: Partial<Record<BL, string>> = {
    FCL: {
      ALL: `MBL: ${mBL} \n HBL: ${hBL}`,
      DirectMBL: `MBL: ${mBL}`,
    },
    LCL: {
      ALL: `MBL: ${mBL} \n HBL: ${hBL}`,
      HBL: `HBL: ${hBL}`,
    },
    AIR: {
      ALL: `MAWB: ${mBL} \n HAWB: ${hBL}`,
      DirectMBL: `MAWB: ${mBL}`,
    },
  }[freightType];

  return (
    <Tooltip
      title={
        <Box sx={{ p: 1, fontSize: 14, whiteSpace: "pre-line" }}>
          BL 유형: {BLLabelRecord[BLType] ?? ""}
          <Divider flexItem sx={{ mt: 1, mb: 1, background: "white" }} />
          {BLInfoRecord[BLType] ?? "입력된 BL 정보가 없습니다."}
        </Box>
      }
      arrow
    >
      <Typography sx={{ ml: 2, color: "#1976d2", fontWeight: 500 }}>
        [BL 정보]
      </Typography>
    </Tooltip>
  );
}
