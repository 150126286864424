import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Divider } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  ForwardingAdminAttachment,
  TrelloBidDetail,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/_shared/src/types/forwarding/trello";

import { useGetParsedWithdrawalFormData } from "../hooks/useGetParsedWithdrawalFormData";
import useHandleSelectedAccount from "../hooks/useHandleSelectedAccount";

import Modal from "../../../../../../components/Modal";

import AttachmentSection from "./AttachmentSection";
import FormSection from "./FormSection";
import Header from "./Header";
import LoadingForParsingFile from "./LoadingForParsingFile";
import OverlayConfirmModal from "./OverlayConfirmModal";

const PurchaseRequestModal = ({
  showsPurchaseInvoiceRequestModal,
  setShowsPurchaseInvoiceRequestModal,
  companyType,
  partnerId,
  exchangeRateList,
  bidAccountPayableId,
  invoiceType,
  trelloDetailData,
  attachmentList,
  partnerName,
  isWithdrawalRequested,
}: {
  companyType: PartnerBusinessArea;
  showsPurchaseInvoiceRequestModal: boolean;
  partnerId: number;
  setShowsPurchaseInvoiceRequestModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  invoiceType: string;
  trelloDetailData: TrelloBidDetail;
  attachmentList: ForwardingAdminAttachment[];
  partnerName: string;
  isWithdrawalRequested: boolean;
}) => {
  const methods = useForm<WithdrawalFormListData>();

  /**
   * 파일 파싱에 성공했을 때, 환율 적용이 필요하여 자식 함수를 호출하기 위해 ref 사용
   * 당장 파일 구조를 변경하긴 어려워 아래와 같은 방법으로 처리함
   *
   * @module CurrencyFilter > useImperativeHandle 참고
   */
  const currencyApplyFunctionRef = useRef<{
    applyCurrency: (withdrawalFormList: WithdrawalFormDetail[]) => void;
  }>(null);

  const applyCurrency = (withdrawalFormList: WithdrawalFormDetail[]) => {
    if (currencyApplyFunctionRef.current) {
      currencyApplyFunctionRef.current.applyCurrency(withdrawalFormList);
    }
    return;
  };

  const [attachmentIndex, setAttachmentIndex] = useState(0);

  const handleAttachmentIndexChange = (index: number) => {
    setAttachmentIndex(index);
  };

  const {
    accountId,
    accountPayment,
    handleAccountIdChange,
    handleAccountPaymentChange,
  } = useHandleSelectedAccount();

  const {
    showsOverlayConfirmModal,
    setShowsOverlayConfirmModal,
    handleOverlayConfirmModalOpen,
    getParsedList,
    handleParsedWithdrawalFormGet,
    isLoadingGettingParsedWithdrawalForm,
  } = useGetParsedWithdrawalFormData({ reset: methods.reset, applyCurrency });

  return (
    <>
      <Modal
        isOpened={showsPurchaseInvoiceRequestModal}
        handleClose={() => {
          setShowsPurchaseInvoiceRequestModal(false);
        }}
        modalBody={
          <Box display="flex" flexDirection={"column"}>
            <Header
              partnerName={partnerName}
              partnerId={partnerId}
              accountId={accountId}
              partnerType={companyType}
              onAccountIdChange={handleAccountIdChange}
              onAccountPaymentChange={handleAccountPaymentChange}
              isWithdrawalRequested={isWithdrawalRequested}
              management={trelloDetailData.management}
              freightType={trelloDetailData.freightType}
            />

            <Divider />

            <FormProvider {...methods}>
              <Box display={"flex"} mt={2}>
                <AttachmentSection
                  key={attachmentIndex}
                  shipmentId={trelloDetailData.id}
                  bidAccountPayableId={bidAccountPayableId}
                  invoiceType={invoiceType}
                  attachmentList={attachmentList}
                  attachmentIndex={attachmentIndex}
                  onAttachmentIndexChange={handleAttachmentIndexChange}
                  onOverlayConfirmModalOpen={handleOverlayConfirmModalOpen}
                  getParsedList={getParsedList}
                />

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ ml: 1, mr: 1 }}
                />

                <FormSection
                  shipmentId={trelloDetailData.id}
                  companyType={companyType}
                  partnerName={partnerName}
                  partnerId={partnerId}
                  exchangeRateList={exchangeRateList}
                  bidAccountPayableId={bidAccountPayableId}
                  trelloDetailData={trelloDetailData}
                  accountId={accountId}
                  accountPayment={accountPayment}
                  onAccountIdChange={handleAccountIdChange}
                  currencyApplyFunctionRef={currencyApplyFunctionRef}
                />
              </Box>
            </FormProvider>
          </Box>
        }
      />

      {/** 매입 파일 읽기 요청 로딩 */}
      <LoadingForParsingFile
        opensModal={isLoadingGettingParsedWithdrawalForm}
      />

      {/** 매입 파일 읽기 확인 모달 */}
      <OverlayConfirmModal
        opensModal={showsOverlayConfirmModal}
        onModalClose={() => setShowsOverlayConfirmModal(false)}
        onParsedWithdrawalFormGet={handleParsedWithdrawalFormGet}
      />
    </>
  );
};

export default PurchaseRequestModal;
