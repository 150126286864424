import { useState } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";

import useGetCargoManifestInfo from "./hooks";
import { BOX_CONTAINER_STYLE, COMMON_BOX_STYLE } from "./styles";

import ArrivalNoticePreview from "./ArrivalNoticePreview";
import CargoManifestInfo from "./CargoManifestInfo";
import ExitModal from "./ExitModal";
import Loading from "./Loading";

export default function PreviewAndFormModal({
  hasCargoManifest,
  showsModal,
  onModalClose,
  shipmentId,
  isImport,
}: {
  hasCargoManifest: boolean;
  showsModal: boolean;
  onModalClose: () => void;
  shipmentId: number;
  isImport: boolean;
}) {
  const [confirmsExit, setConfirmsExit] = useState(false);

  const {
    cargoManifestInfo,
    showsLoading,
    ResponseHandlerOfCreateNewCargoManifestInfo,
  } = useGetCargoManifestInfo({
    shipmentId,
    hasCargoManifest,
  });

  return (
    <>
      <Modal open={showsModal} onClose={() => setConfirmsExit(true)}>
        <Box sx={BOX_CONTAINER_STYLE}>
          <Box sx={{ ...COMMON_BOX_STYLE, left: "27%", overflowY: "auto" }}>
            <ArrivalNoticePreview shipmentId={shipmentId} isImport={isImport} />
          </Box>

          <Box sx={{ ...COMMON_BOX_STYLE, left: "73%" }}>
            {showsLoading && <Loading />}

            {cargoManifestInfo && !showsLoading && (
              <CargoManifestInfo
                cargoManifestInfo={cargoManifestInfo}
                shipmentId={shipmentId}
                isImport={isImport}
              />
            )}
          </Box>
        </Box>
      </Modal>

      <ExitModal
        opensModal={confirmsExit}
        onModalClose={() => setConfirmsExit(false)}
        onButtonClick={onModalClose}
      />

      {ResponseHandlerOfCreateNewCargoManifestInfo}
    </>
  );
}
